import { FlexRowAlignCenter } from "@/components/Flex";
import { fetchFeaturedSubjectCategories } from "@knowt/syncing/hooks/subjects/utils";
import { spacing } from "@/utils/spacing";
import CategoryPill from "./CategoryPill";

const CategoryPicker = ({
    selectedCategory,
    setSelectedCategory,
}: {
    selectedCategory: string | null;
    setSelectedCategory: (category: string | null) => void;
}) => {
    const featuredSubjectCategories = fetchFeaturedSubjectCategories();

    const subjectCategories = featuredSubjectCategories.map(category => {
        return {
            category: category.category,
            image: category.image,
        };
    });

    return (
        <FlexRowAlignCenter style={{ gap: spacing.SM, flexWrap: "wrap" }}>
            <CategoryPill
                category={null}
                image={null}
                isSelected={selectedCategory === null}
                onClick={() => setSelectedCategory(null)}
            />
            {subjectCategories.map(subjectCategory => {
                return (
                    <CategoryPill
                        key={subjectCategory.category}
                        category={subjectCategory.category}
                        image={subjectCategory.image}
                        isSelected={selectedCategory === subjectCategory.category}
                        onClick={() => setSelectedCategory(subjectCategory.category)}
                    />
                );
            })}
        </FlexRowAlignCenter>
    );
};

export default CategoryPicker;
