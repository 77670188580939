"use client";

import styles from "./footer.module.css";
import { ConditionalLink } from "@/components/ConditionalLink";
import { URLS } from "@/config/constants";
import FooterAds from "@/features/Ads/components/FooterAds";
import { showIntercom } from "@/utils/intercom";
import { Facebook, Linkedin, Twitter } from "lucide-react";
import { CSSProperties } from "react";
import { InstagramIcon, TiktokIcon } from "./CustomIcons";
import SocialMediaButton from "./SocialMediaButton";
import { FlexColumn, FlexRowAlignCenter } from "../Flex";
import br from "@/styles/breakpoints.module.css";
import { clsx } from "clsx";
import RepeatAutoFillGrid from "../styled/div/RepeatAutoFillGrid";
import { useGlobalSidebarToggler } from "@/features/Sidebar/Sidebar";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import toast from "react-hot-toast";

const GET_KNOWT_LINKS = [
    { href: "/mobile", text: "Mobile App" },
    { href: "/chrome-extension", text: "Chrome Extension" },
    { href: "/schools/bulk-pricing", text: "Bulk Discounts" },
    { href: "/plans", text: "Student Plans" },
    { href: "/plans/teachers", text: "Teacher Plans" },
    { href: "/free-quizlet-alternative", text: "Knowt vs Quizlet" },
    { href: "/free-fiveable", text: "Knowt vs Fiveable" },
];

const STUDY_TOOLS_LINKS = [
    { href: "/", text: "AI Flashcards" },
    { href: "/ai-pdf-summarizer", text: "AI PDF Summarizer" },
    { href: "/ai-powerpoint-summarizer", text: "AI PPT Summarizer" },
    { href: "/ai-video-summarizer", text: "AI Video Summarizer" },
    { href: "/ai-lecture-note-taker", text: "AI Lecture Note Taker" },
    { href: "/ai-spreadsheet-summarizer", text: "AI Spreadsheet Summarizer" },
    { href: "/ai-notes", text: "AI Notes" },
    { href: "/flashcards", text: "Flashcard Maker" },
];

const EXAM_TYPE_LINKS = [
    { href: "/exams/AP", text: "AP Exam Hub" },
    { href: "/exams/IB", text: "IB Exam Hub" },
    { href: "/exams/GCSE", text: "GCSE Exam Hub" },
    { href: "/exams/A-Level", text: "A-Level Exam Hub" },
    { href: "/exams", text: "More Exam Hubs" },
    { href: "/exams/AP/practice-test-room", text: "Practice Test Room" },
    { href: "/exams/AP/frq-room", text: "Free-Response Room" },
    { href: "/exams/AP/score-calculator", text: "AP Score Calculator" },
];

const SUBJECT_LINKS = [
    { href: "/subject/Science", text: "Science" },
    { href: "/subject/Social-Studies", text: "Social Studies" },
    { href: "/subject/Language", text: "Language" },
    { href: "/subject/Math", text: "Math" },
    { href: "/subject/Engineering", text: "Engineering" },
    { href: "/subject/Business", text: "Business" },
];

type ItemLinkProps = {
    href?: string;
    onClick?: () => void;
    text: string;
    openInNewTab?: boolean;
};

const ItemLink = ({ href, onClick, text, openInNewTab }: ItemLinkProps) => (
    <ConditionalLink
        condition={!!href}
        href={href}
        openInNewTab={openInNewTab}
        style={{ fontSize: "1.8rem", wordBreak: "break-word" }}
        className={styles.footerSmItemLinkFontSize}>
        <p onClick={onClick} style={{ cursor: "pointer" }}>
            {text}
        </p>
    </ConditionalLink>
);

const FooterColumn = ({
    title,
    links,
    privacy = false,
}: {
    title: string;
    links: ItemLinkProps[];
    privacy?: boolean;
}) => {
    return (
        <FlexColumn>
            <div style={{ fontSize: "2.2rem", fontWeight: "800", marginBottom: "2rem" }}>{title}</div>
            <FlexColumn style={{ gap: "0.8rem" }}>
                {links.map(item => (
                    <ItemLink key={item.text} {...item} />
                ))}
                {privacy && (
                    <div
                        style={{ fontSize: "1.8rem", cursor: "pointer" }}
                        id="pmLink"
                        className={styles.footerSmItemLinkFontSize}>
                        Privacy Manager
                    </div>
                )}
            </FlexColumn>
        </FlexColumn>
    );
};

const BottomRow = () => {
    return (
        <FlexRowAlignCenter
            style={{ justifyContent: "space-between", gap: "2rem", marginBottom: "2rem" }}
            className={br.mdDownColumnReverse}>
            <div style={{ fontWeight: 500, fontSize: "1.8rem" }}>© 2024 Knowt Inc.</div>
            <FlexRowAlignCenter style={{ gap: "1.5rem" }}>
                <SocialMediaButton label="Tiktok" href="https://www.tiktok.com/@getknowt">
                    {TiktokIcon}
                </SocialMediaButton>
                <SocialMediaButton label="Instagram" href="https://instagram.com/getknowt">
                    {InstagramIcon}
                </SocialMediaButton>
                <SocialMediaButton label="Facebook" Icon={Facebook} href="https://www.facebook.com/getknowt" />
                <SocialMediaButton label="Twitter" Icon={Twitter} href="https://twitter.com/getknowt" />
                <SocialMediaButton label="Linkedin" Icon={Linkedin} href="https://www.linkedin.com/company/knowt" />
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};

const APCopyrightNotice = () => {
    const message =
        "Advanced Placement® AP®, and SAT® are trademarks registered by the College Board, which is not affiliated with, and does not endorse, this product. ACT® is a trademark registered by the ACT, Inc, which is not affiliated with, and does not endorse, this product.";

    return (
        <p
            style={{ fontSize: "1rem", width: "50%" }}
            className={clsx(br.mdDownTextAlignCenter, styles.footerMdSideMarginAuto)}>
            {message}
        </p>
    );
};

const Footer = ({ ad = false, landing = false, style }: { ad?: boolean; landing?: boolean; style?: CSSProperties }) => {
    const { isExpanded } = useGlobalSidebarToggler();
    const { userId } = useCurrentUser();

    const resourceLinks = [
        { href: "https://help.knowt.com/en/", text: "FAQ" },
        {
            text: "Contact Us",
            onClick: () => {
                if (!userId) return toast.error("Please login to contact us");
                showIntercom();
            },
        },
        { href: "/knowttakers", text: "KnowtTakers" },
        { href: "https://connect.studentbeans.com/v4/hosted/knowt/us", text: "Student Discounts" },
        { href: "/blog/", text: "Blog" },
        // { href: "/updates", text: "Knowt Updates" },
        { href: URLS.DMCA_TAKEDOWN_REQUEST, text: "DMCA Takedown", openInNewTab: true },
        { href: "/privacy/", text: "Privacy Policy" },
        { href: "/coppa-notice/", text: "COPPA Notice" },
        { href: "/terms/", text: "Terms of Service" },
        // { href: "/features", text: "Feature Requests" },
    ];

    return (
        <footer
            className={clsx(styles.footerSmSidePadding, styles.footerMdSidePadding)}
            style={{
                paddingTop: "5rem",
                paddingInline: landing ? "4rem" : `${!isExpanded ? "12rem" : "28rem"} 4rem`,
                paddingBottom: ad ? "13rem" : "1rem",
                ...style,
            }}>
            <FlexColumn>
                <RepeatAutoFillGrid
                    itemMinWidth="22rem"
                    style={{
                        gap: "2rem 5rem",
                        margin: "0 auto",
                        marginBottom: "2rem",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                    className={clsx(styles.footerMdGridGap, styles.footerSmGridTemplateColumns)}>
                    <FooterColumn title="Get Knowt" links={GET_KNOWT_LINKS} />
                    <FooterColumn title="Study Tools" links={STUDY_TOOLS_LINKS} />
                    <FooterColumn title="Exams" links={EXAM_TYPE_LINKS} />
                    <FooterColumn title="Resources" links={resourceLinks} privacy />
                    <FooterColumn title="Subjects" links={SUBJECT_LINKS} />
                </RepeatAutoFillGrid>
                <BottomRow />
                <APCopyrightNotice />
            </FlexColumn>
            {ad && <FooterAds />}
        </footer>
    );
};

export default Footer;
