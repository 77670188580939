import { useSWRImmutable } from "@/hooks/swr/immutable";

export const useDetectAdBlock = () => {
    const { data: areAdsBlocked } = useSWRImmutable("ad-blocker-detection", () => {
        return fetch("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", {
            method: "HEAD",
            mode: "no-cors",
            cache: "no-store",
        })
            .then(() => false)
            .catch(() => true);
    });

    return !!areAdsBlocked;
};
