import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { Sparkle } from "lucide-react";
import Box from "@mui/material/Box";
import Image from "next/image";

const CategoryPill = ({
    category,
    image,
    isSelected,
    onClick,
}: { category: string | null; image: string | null; isSelected: boolean; onClick: () => void }) => {
    return (
        <Box
            component="button"
            onClick={onClick}
            className="secondaryTextBold1"
            sx={{
                minWidth: "6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: isSelected ? themeColors.neutralBlack : themeColors.neutralWhite,
                borderRadius: borderRadius.shortInput,
                padding: `${spacing.XS} ${spacing.SM}`,
                gap: spacing.XS,
                border: `none`,
                cursor: "pointer",
                color: isSelected ? themeColors.neutralWhite : themeColors.neutralBlack,
                fontFamily: "var(--knowt-font-name)",
                "&:hover": {
                    outline: `1px solid ${themeColors.neutralBlack}`,
                },
            }}>
            {image ? (
                <Image src={image} alt={category ?? "All"} width={20} height={20} style={{ objectFit: "contain" }} />
            ) : (
                <Sparkle size={24} color={isSelected ? themeColors.neutralWhite : themeColors.neutralBlack} />
            )}
            <span>{category ?? "All"}</span>
        </Box>
    );
};

export default CategoryPill;
