export const SUBJECT_TO_CATEGORY: { [key: string]: string } = {
    Herpetology: "Science",
    Korean: "Language",
    Aviation: "Engineering",
    "Chemical Engineering": "Engineering",
    Sociology: "Social Studies",
    Indonesian: "Language",
    "Geometry & Trigonometry": "Math",
    "Civil Engineering": "Engineering",
    Pathology: "Science",
    "Child Development": "Social Studies",
    Philosophy: "Social Studies",
    English: "Language",
    Algebra: "Math",
    Marketing: "Business",
    "Other Subject": "Other",
    "World History": "Social Studies",
    Theology: "Social Studies",
    Calculus: "Math",
    Geography: "Social Studies",
    Sports: "Other",
    Geology: "Science",
    "Media Studies": "Social Studies",
    "Pre-Calculus": "Math",
    Other: "Other",
    Law: "Social Studies",
    Spanish: "Language",
    German: "Language",
    Architecture: "Engineering",
    Microeconomics: "Business",
    Portuguese: "Language",
    "Computer Engineering": "Engineering",
    "Art History": "Social Studies",
    "Information and Communication Technology": "Engineering",
    Dutch: "Language",
    "Sign Language": "Language",
    Chinese: "Language",
    "Other Engineering": "Engineering",
    "Forensic Science": "Science",
    "Supply Chain": "Business",
    "Web Design": "Engineering",
    "Communication Science": "Science",
    French: "Language",
    Management: "Business",
    Welsh: "Language",
    "US History": "Social Studies",
    Russian: "Language",
    "Environmental Science": "Science",
    Government: "Social Studies",
    Statistics: "Math",
    Biology: "Science",
    Health: "Science",
    Nursing: "Science",
    "Computer Science": "Engineering",
    Japanese: "Language",
    Polish: "Language",
    "Aerospace Engineering": "Engineering",
    Logic: "Social Studies",
    Music: "Social Studies",
    Hindi: "Language",
    Macroeconomics: "Business",
    "European History": "Social Studies",
    Italian: "Language",
    "Computer Information System": "Engineering",
    Latin: "Language",
    "Religious Studies": "Social Studies",
    Chemistry: "Science",
    Physics: "Science",
    Finance: "Business",
    Thai: "Language",
    "Physical Science": "Science",
    "Electrical Engineering": "Engineering",
    Nutrition: "Science",
    Accounting: "Business",
    Psychology: "Social Studies",
    "Mechanical Engineering": "Engineering",
    "Graphic Arts": "Social Studies",
    Medicine: "Science",
    Astronomy: "Science",
    Economics: "Business",
    "Biomedical Engineering": "Engineering",
    Arabic: "Language",
    Neuroscience: "Science",
};

export const CATEGORY_TO_SUBJECT: { [key: string]: string[] } = {
    Science: [
        "Herpetology",
        "Pathology",
        "Geology",
        "Forensic Science",
        "Biology",
        "Health",
        "Nursing",
        "Environmental Science",
        "Chemistry",
        "Physics",
        "Nutrition",
        "Medicine",
        "Astronomy",
        "Neuroscience",
    ],
    Language: [
        "Korean",
        "Indonesian",
        "English",
        "Spanish",
        "German",
        "Portuguese",
        "Dutch",
        "Sign Language",
        "Chinese",
        "French",
        "Welsh",
        "Russian",
        "Japanese",
        "Polish",
        "Hindi",
        "Italian",
        "Latin",
        "Thai",
        "Arabic",
    ],
    Engineering: [
        "Aviation",
        "Chemical Engineering",
        "Civil Engineering",
        "Architecture",
        "Computer Engineering",
        "Information and Communication Technology",
        "Other Engineering",
        "Web Design",
        "Computer Science",
        "Aerospace Engineering",
        "Electrical Engineering",
        "Mechanical Engineering",
        "Biomedical Engineering",
    ],
    "Social Studies": [
        "Sociology",
        "Child Development",
        "Philosophy",
        "World History",
        "Theology",
        "Geography",
        "Media Studies",
        "Law",
        "Art History",
        "US History",
        "Government",
        "Logic",
        "Music",
        "European History",
        "Religious Studies",
        "Psychology",
        "Graphic Arts",
    ],
    Math: ["Geometry & Trigonometry", "Algebra", "Calculus", "Statistics"],
    Business: [
        "Marketing",
        "Microeconomics",
        "Supply Chain",
        "Management",
        "Macroeconomics",
        "Economics",
        "Finance",
        "Accounting",
    ],
    Other: ["Other Subject", "Sports", "Other"],
};

export const TOPIC_TO_SUBJECT: { [key: string]: string } = {
    Transformations: "Geometry & Trigonometry",
    Congruence: "Geometry & Trigonometry",
    Similarity: "Geometry & Trigonometry",
    "Right Triangles & Trigonometry": "Geometry & Trigonometry",
    "Conic Sections": "Geometry & Trigonometry",
    Circles: "Geometry & Trigonometry",
    "3D Shapes": "Geometry & Trigonometry",
    Ethics: "Philosophy",
    Metaphysics: "Philosophy",
    Epistemology: "Philosophy",
    "Social & Political Philosophy": "Philosophy",
    Grammar: "English",
    Literature: "English",
    Vocabulary: "English",
    "Linear Algebra": "Algebra",
    "Pre-Algebra": "Algebra",
    "System of Equations": "Algebra",
    Inequalities: "Algebra",
    Functions: "Algebra",
    "Irrational Numbers": "Algebra",
    Slopes: "Algebra",
    "Ancient Societies": "World History",
    "International Trade": "World History",
    Imperialism: "World History",
    "Political Revolutions": "World History",
    "World Religions": "World History",
    "Exploration & Colonization": "World History",
    "World Wars": "World History",
    Globalization: "World History",
    "Limits and Continuity": "Calculus",
    "Derivatives & Differentiation": "Calculus",
    Integration: "Calculus",
    "Differential Equations": "Calculus",
    "Parametric Equations & Polar Coordinates": "Calculus",
    "Infinite Sequences": "Calculus",
    "Population Patterns": "Geography",
    "Migration Patterns": "Geography",
    "Cultural Patterns": "Geography",
    "Rural Land Patterns": "Geography",
    "Urban Land Patterns": "Geography",
    "Economic Patterns": "Geography",
    "Political Patterns": "Geography",
    Criminology: "Law",
    "Criminal Justice": "Law",
    "Perfect vs. Imperfect Competition": "Microeconomics",
    "Supply and Demand": "Microeconomics",
    "Market Failure": "Microeconomics",
    "Cost Curves": "Microeconomics",
    "Brazillian Portugese": "Portuguese",
    "European Portugese": "Portuguese",
    "Composite & Inverse Functions": "Pre-Calculus",
    "Complex Numbers": "Pre-Calculus",
    "Rational Functions": "Pre-Calculus",
    Vectors: "Pre-Calculus",
    Matrices: "Pre-Calculus",
    Series: "Pre-Calculus",
    "Speech Pathology": "Communication Science",
    Audiology: "Communication Science",
    "Pre-Colonization": "US History",
    "13 Colonies": "US History",
    "Revolutionary War": "US History",
    "Civil War": "US History",
    Industrialization: "US History",
    "Cold War": "US History",
    "Civil Rights Movement": "US History",
    "Modern America": "US History",
    Reconstruction: "US History",
    "Spanish-American War": "US History",
    "Ecosystems & Biodiversity": "Environmental Science",
    Populations: "Environmental Science",
    "Earth Systems and Resources": "Environmental Science",
    "Land and Water Use": "Environmental Science",
    "Energy Resources and Consumption": "Environmental Science",
    Pollution: "Environmental Science",
    "Global Change": "Environmental Science",
    "Climate Science": "Environmental Science",
    Democracy: "Government",
    Politics: "Government",
    Laws: "Government",
    "Public Policy": "Government",
    "International Relations": "Government",
    "Categorical Data": "Statistics",
    "One-Variable & Two-Variable Data": "Statistics",
    "Hypothesis Testing: Proportions & Means": "Statistics",
    Probability: "Statistics",
    "Collecting Data": "Statistics",
    "Sampling Distributions": "Statistics",
    "Normal, Binomial, Poisson, & Chi-Square": "Statistics",
    "F Distribution and One Way ANOVA": "Statistics",
    "Linear Regression and Correlation": "Statistics",
    Biochemistry: "Biology",
    Cells: "Biology",
    Genetics: "Biology",
    Evolution: "Biology",
    Anatomy: "Biology",
    Botany: "Biology",
    Microbiology: "Biology",
    Physiology: "Biology",
    Zoology: "Biology",
    "Sex Education": "Health",
    "Medical-Surgical": "Nursing",
    "Nursing Research": "Nursing",
    "Supply & Demand": "Macroeconomics",
    "Business Cycles": "Macroeconomics",
    "Monetary Policy": "Macroeconomics",
    Inflation: "Macroeconomics",
    "Economic Growth": "Macroeconomics",
    GDP: "Macroeconomics",
    Renaissance: "European History",
    "Age of Reformation": "European History",
    Absolutism: "European History",
    Enlightenment: "European History",
    "Growth of British Power": "European History",
    "Effects of Global Conflicts": "European History",
    "Contemporary Europe": "European History",
    "British History": "European History",
    Atoms: "Chemistry",
    "Molecular & Ionic Compounds": "Chemistry",
    "Intermolecular Forces": "Chemistry",
    "Chemical Reactions": "Chemistry",
    Kinetics: "Chemistry",
    Thermodynamics: "Chemistry",
    Equilibrium: "Chemistry",
    "Acids and Bases": "Chemistry",
    "Organic Chemistry": "Chemistry",
    "Kinematics & Dynamics": "Physics",
    "Newton's Laws": "Physics",
    "Circular Motion & Gravitation": "Physics",
    "Energy & Momentum": "Physics",
    "Simple Harmonic & Rotational Motion": "Physics",
    Fluids: "Physics",
    "Electric Charge, Field, & Potential": "Physics",
    Circuits: "Physics",
    "Magnetic Forces/Fields": "Physics",
    "Electromagnetic Waves": "Physics",
    "Geometric Optics": "Physics",
    "Quantum Physics": "Physics",
    "Thermal Physics": "Physics",
    Kinesiology: "Physics",
    Investments: "Finance",
    "Financial Statements": "Finance",
    "Banking & Regulation": "Finance",
    "Biological Psychology": "Psychology",
    "Sensation and Perception": "Psychology",
    "Psychology of Learning": "Psychology",
    "Analytical Psychology": "Psychology",
    "Developmental Psychology": "Psychology",
    "Psychology & Personality": "Psychology",
    "Clinical Psychology": "Psychology",
    "Social Psychology": "Psychology",
    "Cognitive Psychology": "Psychology",
    "Abnormal Psychology": "Psychology",
    "Medical Terminology": "Medicine",
    "Origins of the Universe": "Astronomy",
    Spectroscopy: "Astronomy",
};

export const SUBJECT_TO_TOPICS: Record<string, string[]> = {
    "Geometry & Trigonometry": [
        "Transformations",
        "Congruence",
        "Similarity",
        "Right Triangles & Trigonometry",
        "Conic Sections",
        "Circles",
        "3D Shapes",
    ],
    Philosophy: ["Ethics", "Metaphysics", "Epistemology", "Social & Political Philosophy"],
    English: ["Grammar", "Literature", "Vocabulary"],
    Algebra: [
        "Linear Algebra",
        "Pre-Algebra",
        "System of Equations",
        "Inequalities",
        "Functions",
        "Irrational Numbers",
        "Slopes",
    ],
    "World History": [
        "Ancient Societies",
        "International Trade",
        "Imperialism",
        "Political Revolutions",
        "World Religions",
        "Exploration & Colonization",
        "World Wars",
        "Globalization",
    ],
    Calculus: [
        "Limits and Continuity",
        "Derivatives & Differentiation",
        "Integration",
        "Differential Equations",
        "Parametric Equations & Polar Coordinates",
        "Infinite Sequences",
    ],
    Geography: [
        "Population Patterns",
        "Migration Patterns",
        "Cultural Patterns",
        "Rural Land Patterns",
        "Urban Land Patterns",
        "Economic Patterns",
        "Political Patterns",
    ],
    Law: ["Criminology", "Criminal Justice"],
    Microeconomics: ["Perfect vs. Imperfect Competition", "Supply and Demand", "Market Failure", "Cost Curves"],
    Portuguese: ["Brazillian Portugese", "European Portugese"],
    "Pre-Calculus": [
        "Composite & Inverse Functions",
        "Complex Numbers",
        "Rational Functions",
        "Vectors",
        "Matrices",
        "Series",
    ],
    "Communication Science": ["Speech Pathology", "Audiology"],
    "US History": [
        "Pre-Colonization",
        "13 Colonies",
        "Revolutionary War",
        "Civil War",
        "Industrialization",
        "Cold War",
        "Civil Rights Movement",
        "Modern America",
        "Reconstruction",
        "Spanish-American War",
    ],
    "Environmental Science": [
        "Ecosystems & Biodiversity",
        "Populations",
        "Earth Systems and Resources",
        "Land and Water Use",
        "Energy Resources and Consumption",
        "Pollution",
        "Global Change",
        "Climate Science",
    ],
    Government: ["Democracy", "Politics", "Laws", "Public Policy", "International Relations"],
    Statistics: [
        "Categorical Data",
        "One-Variable & Two-Variable Data",
        "Hypothesis Testing: Proportions & Means",
        "Probability",
        "Collecting Data",
        "Sampling Distributions",
        "Normal, Binomial, Poisson, & Chi-Square",
        "F Distribution and One Way ANOVA",
        "Linear Regression and Correlation",
    ],
    Biology: [
        "Biochemistry",
        "Cells",
        "Genetics",
        "Evolution",
        "Anatomy",
        "Botany",
        "Microbiology",
        "Physiology",
        "Zoology",
    ],
    Health: ["Sex Education"],
    Nursing: ["Medical-Surgical", "Nursing Research"],
    Macroeconomics: ["Supply & Demand", "Business Cycles", "Monetary Policy", "Inflation", "Economic Growth", "GDP"],
    "European History": [
        "Renaissance",
        "Age of Reformation",
        "Absolutism",
        "Enlightenment",
        "Growth of British Power",
        "Effects of Global Conflicts",
        "Contemporary Europe",
        "British History",
    ],
    Chemistry: [
        "Atoms",
        "Molecular & Ionic Compounds",
        "Intermolecular Forces",
        "Chemical Reactions",
        "Kinetics",
        "Thermodynamics",
        "Equilibrium",
        "Acids and Bases",
        "Organic Chemistry",
    ],
    Physics: [
        "Kinematics & Dynamics",
        "Newton's Laws",
        "Circular Motion & Gravitation",
        "Energy & Momentum",
        "Simple Harmonic & Rotational Motion",
        "Fluids",
        "Electric Charge, Field, & Potential",
        "Circuits",
        "Magnetic Forces/Fields",
        "Electromagnetic Waves",
        "Geometric Optics",
        "Quantum Physics",
        "Thermal Physics",
        "Kinesiology",
    ],
    Finance: ["Investments", "Financial Statements", "Banking & Regulation"],
    Psychology: [
        "Biological Psychology",
        "Sensation and Perception",
        "Psychology of Learning",
        "Analytical Psychology",
        "Developmental Psychology",
        "Psychology & Personality",
        "Clinical Psychology",
        "Social Psychology",
        "Cognitive Psychology",
        "Abnormal Psychology",
    ],
    Medicine: ["Medical Terminology"],
    Astronomy: ["Origins of the Universe", "Spectroscopy"],
};
