"use client";

import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import Collapse from "@mui/material/Collapse";
import { ChevronDown } from "lucide-react";
import React, { useState } from "react";
import CircularButton from "../CircularButton";
import styles from "./faq.module.css";
import clsx from "clsx";

const Ul = ({ children, style, ...ulProps }: React.HTMLAttributes<HTMLUListElement>) => {
    return (
        <ul {...ulProps} style={{ lineHeight: "2.4rem", ...style }} className="strippedList">
            {children}
        </ul>
    );
};

const Li = ({ children, style, ...liProps }: React.HTMLAttributes<HTMLLIElement>) => {
    return (
        <li {...liProps} style={{ marginBottom: "2.6rem", ...style }}>
            {children}
        </li>
    );
};

const FAQ = ({
    title = "Questions & Answers",
    titleClassName,
    titleStyle,
    description = "If you have any other questions - please get in touch",
    containerStyle,
    questStyle,
    className,
    faqs,
    defaultCollapsed = false,
}: {
    title?: React.ReactNode;
    titleClassName?: string;
    titleStyle?: React.CSSProperties;
    description?: string | null;
    containerStyle?: React.CSSProperties;
    questStyle?: React.CSSProperties;
    className?: string;
    faqs: {
        question: string | React.ReactNode;
        answer: React.ReactNode[] | React.ReactNode;
    }[];
    defaultCollapsed?: boolean;
}) => {
    const [expanded, setExpanded] = useState<boolean[]>(faqs?.map(() => !defaultCollapsed));

    const toggleExpanded = (index: number) => {
        setExpanded(prev => {
            const newExpanded = [...prev];
            newExpanded[index] = !prev[index];
            return newExpanded;
        });
    };

    if (!faqs?.length) {
        return null;
    }

    return (
        <FlexColumn className={className} style={{ justifyContent: "center", ...containerStyle }}>
            <FlexColumnAlignJustifyCenter>
                <span className={clsx("heading2", titleClassName)} style={{ ...titleStyle }}>
                    {title}
                </span>
                {description && (
                    <span className="bodyXL" style={{ textAlign: "center" }}>
                        {description}
                    </span>
                )}
            </FlexColumnAlignJustifyCenter>
            <FlexColumn style={{ gap: spacing.MD, marginTop: spacing.LG_3 }}>
                {faqs.map((faq, index) => (
                    <FlexColumn
                        key={index}
                        onClick={() => toggleExpanded(index)}
                        style={{ cursor: "pointer", gap: spacing.SM }}>
                        <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                            <div style={{ maxWidth: "80%", fontWeight: "700", ...questStyle }}>{faq.question}</div>
                            <CircularButton
                                radius="3.4rem"
                                aria-label="toggle expand"
                                sx={{
                                    backgroundColor: themeColors.neutralWhite,
                                    transition: "transform 0.2s ease-in-out",
                                    transform: expanded[index] ? "rotate(180deg)" : "rotate(0deg)",
                                }}
                                tooltip={expanded[index] ? "Collapse" : "Expand"}>
                                <ChevronDown size={24} />
                            </CircularButton>
                        </FlexRowAlignCenter>
                        <Collapse className={styles.answer} in={expanded[index]}>
                            {!Array.isArray(faq.answer) ? (
                                <span>{faq.answer}</span>
                            ) : (
                                <Ul>
                                    {faq.answer.map((ans, idx) => {
                                        const isLast = idx === (faq.answer as React.ReactNode[]).length - 1;
                                        return (
                                            <Li key={idx} style={{ marginBottom: isLast ? 0 : "3.2rem" }}>
                                                {ans}
                                            </Li>
                                        );
                                    })}
                                </Ul>
                            )}
                        </Collapse>
                    </FlexColumn>
                ))}
            </FlexColumn>
        </FlexColumn>
    );
};

export default FAQ;
