import { Subject } from "@knowt/syncing/graphql/schema";
import { AP_EXAM_NAMES, IB_EXAM_NAMES } from "@knowt/syncing/hooks/exams/constants";

const getOrderedSubjectsList = (examName: string) => {
    switch (examName) {
        case "AP":
            return AP_EXAM_NAMES;
        case "IB":
            return IB_EXAM_NAMES;
        default:
            undefined;
    }
};

export const getExamOrderedSubjects = (examType: string | undefined, subjects: Subject[]) => {
    if (!examType) {
        return subjects;
    }

    const orderedSubjectsList = getOrderedSubjectsList(examType);

    if (!orderedSubjectsList) {
        return subjects;
    }

    return orderedSubjectsList.map(subject => subjects.find(s => s.subject?.toLowerCase() === subject.toLowerCase()));
};
