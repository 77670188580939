import { themeColors } from "@/utils/themeColors";
import LinkWrapper from "@/components/wrappers/Link";
import { spacing } from "@/utils/spacing";
import Box from "@mui/material/Box";
import Image from "next/image";
import { borderRadius } from "@/utils/borderRadius";
import { FlexColumn } from "@/components/Flex";

const ExploreItemCard = ({
    route,
    image,
    title,
    size = "small",
    customLinkSuffix,
    customBottomText,
}: {
    route: string;
    image: string;
    title: string;
    size?: "small" | "large";
    customLinkSuffix?: string;
    customBottomText?: string;
}) => {
    return (
        <LinkWrapper href={route + (customLinkSuffix ? `/${customLinkSuffix}` : "")}>
            <Box
                sx={{
                    width: "100%",
                    padding: spacing.SM,
                    color: themeColors.neutralBlack,
                    borderRadius: borderRadius.card,
                    backgroundColor: themeColors.neutralWhite,
                    "&:hover": {
                        outline: `1px solid ${themeColors.neutralBlack}`,
                    },
                    height: size === "small" ? "15rem" : "20rem",
                }}>
                <Image src={image} alt={title} width={36} height={36} />
                <FlexColumn style={{ marginTop: spacing.SM, width: "100%", maxWidth: "17rem", gap: spacing.XS }}>
                    <span className={"bodyBold2"}>{title}</span>
                    <span className="secondaryText1">{customBottomText ?? "Notes & Flashcards"}</span>
                </FlexColumn>
            </Box>
        </LinkWrapper>
    );
};

export default ExploreItemCard;
