import { objectWithout } from "@/utils/dataCleaning";
import { fromEntries } from "@/utils/genericUtils";
import { FrontendFeaturedCategory, getImageLink } from "../exams/utils";
import { SubjectMetadata } from "@/graphql/customSchema";
import { SUBJECT_TO_CATEGORY } from "./constants";

export const subjectCategoryLookup = (subject: string | null | undefined) => {
    // TODO: turn fetchSubjects into a hook fetching by SWR, so we can dedup the calls.
    return SUBJECT_TO_CATEGORY[subject || ""] || null;
};

/**
 * returns all the subjects grouped by category
 * @returns Object<{category: {subjects: Array, ...categoryData}}>
 */
export const fetchSubjectsByCategory = () => {
    const subjects = Object.entries(SUBJECT_TO_CATEGORY).map(([subject, category]) => ({ subject, category }));

    return groupSubjectsByCategory(subjects);
};

export const fetchFeaturedSubjectCategories = () => {
    const subjectsByCategory = fetchSubjectsByCategory();
    return getFeaturedCategories(subjectsByCategory);
};

const getFeaturedCategories = subjectsByCategory => {
    return Object.values(objectWithout(subjectsByCategory, "Other")).map(
        backendToFrontendFeaturedCategory
    ) as FrontendFeaturedCategory[];
};

export const groupSubjectsByCategory = (subjects: { subject: string; category: string }[]) => {
    const categoriesWithSubjects = subjects.map(({ category }) => ({
        category,
        subjects: subjects.filter(subject => subject.category === category).map(({ subject }) => subject),
    }));

    return fromEntries(categoriesWithSubjects.map(item => [item.category, item])) as Record<
        string,
        { category: string; subjects: string[] }
    >;
};

const backendToFrontendFeaturedCategory = ({
    category,
    subjects,
}: {
    category: SubjectMetadata["category"];
    subjects: string[];
}) => ({
    category,
    image: getImageLink(category),
    subjects: subjects.map(subject => ({
        subject,
        route: `/subject/${subjectNameToUrlComponent(category)}/${subjectNameToUrlComponent(subject)}-flashcards`,
        image: getImageLink(subject),
    })),
    route: `/subject/${subjectNameToUrlComponent(category)}-flashcards`,
});

export const subjectNameToUrlComponent = (name: string) =>
    encodeURIComponent((name || "").replace(/: /g, "_").replace(/ - /g, "=").replace(/-/g, ".").replace(/ /g, "-"));

export const subjectUrlComponentToName = (urlComponent: string | undefined) =>
    decodeURIComponent(urlComponent)?.replace(/_/g, ": ").replace(/-/g, " ").replace(/\./g, "-").replace(/=/g, " - ");

export const getSubjectNameAndType = (param: string) =>
    param
        ? {
              type: param.split("-").pop() as "notes" | "flashcards" | string,
              param: subjectUrlComponentToName(param.split("-").slice(0, -1).join("-")),
          }
        : { type: undefined, param: undefined };
