"use client";

import { useMemo, useState } from "react";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import CategoryPicker from "../../ExplorePage/components/CategoryPicker";
import ExploreItemCard from "../../ExplorePage/components/ExploreItemCard";
import { FrontendFeaturedCategory } from "@knowt/syncing/hooks/exams/utils";
import RepeatAutoFillGrid from "@/components/styled/div/RepeatAutoFillGrid";
import InputWithSearchbarIcon from "@/components/styled/input/InputWithSearchbarIcon";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { getExamOrderedSubjects } from "../utils/examsUtils";

const ExamPicker = ({
    featuredExamCategories,
    customLinkSuffix = undefined,
    customBottomText,
    hideSearchBar = false,
    examType,
}: {
    featuredExamCategories: FrontendFeaturedCategory[];
    customLinkSuffix?: string;
    customBottomText?: string;
    hideSearchBar?: boolean;
    examType?: string;
}) => {
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const examsToShow = useMemo(() => {
        let subjects = selectedCategory
            ? featuredExamCategories.find(category => category.category === selectedCategory)?.subjects
            : featuredExamCategories.flatMap(category => category.subjects);

        if (searchQuery) {
            subjects = subjects?.filter(subject => subject.subject.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        return getExamOrderedSubjects(examType, subjects || []);
    }, [featuredExamCategories, selectedCategory, searchQuery, examType]);

    return (
        <FlexColumnAlignJustifyCenter style={{ gap: "4rem", width: "100%", position: "relative" }}>
            {!hideSearchBar && (
                <InputWithSearchbarIcon
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    placeholder="Search for an exam"
                    sx={{
                        width: "100%",
                        padding: "1rem",
                        backgroundColor: themeColors.neutralWhite,
                        borderRadius: borderRadius.shortInput,
                        paddingLeft: spacing.LG_3,
                    }}
                    containerStyle={{
                        width: "100%",
                        maxWidth: "48rem",
                    }}
                    adornmentProps={{
                        style: {
                            color: themeColors.neutral2,
                        },
                    }}
                />
            )}
            <CategoryPicker selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            <RepeatAutoFillGrid itemMinWidth="21rem" style={{ gap: "2.4rem 2rem" }}>
                {examsToShow?.map(exam => {
                    return (
                        <ExploreItemCard
                            key={exam.subject}
                            route={`${exam.route}${customLinkSuffix ? `/${customLinkSuffix}` : ""}`}
                            image={exam.image}
                            title={exam.subject}
                            size={"large"}
                            customBottomText={customBottomText}
                        />
                    );
                })}
            </RepeatAutoFillGrid>
        </FlexColumnAlignJustifyCenter>
    );
};

export default ExamPicker;
