import { themeColors } from "@/utils/themeColors";
import { iconSizes } from "@/utils/iconProps";

export const InstagramIcon = (
    <svg width={iconSizes.MD} height={iconSizes.MD} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>instagram</title>
        <path
            d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
            fill={themeColors.neutralBlack}
            stroke={themeColors.neutralBlack}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 11.3703C16.1234 12.2025 15.9813 13.0525 15.5938 13.7993C15.2063 14.5461 14.5931 15.1517 13.8416 15.53C13.0901 15.9082 12.2384 16.0399 11.4078 15.9062C10.5771 15.7726 9.80976 15.3804 9.21484 14.7855C8.61992 14.1905 8.22773 13.4232 8.09407 12.5925C7.9604 11.7619 8.09207 10.9102 8.47033 10.1587C8.84859 9.40716 9.45419 8.79404 10.201 8.40654C10.9478 8.01904 11.7978 7.87689 12.63 8.0003C13.4789 8.12619 14.2649 8.52176 14.8717 9.12861C15.4785 9.73545 15.8741 10.5214 16 11.3703Z"
            stroke={themeColors.neutralWhite}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 6.5H17.51"
            stroke={themeColors.neutralWhite}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const TiktokIcon = (
    <svg width={iconSizes.MD} height={iconSizes.MD} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>tiktok</title>
        <path
            d="M21.5769 5.76758C20.2643 5.76758 19.0532 5.33273 18.0806 4.59916C16.9652 3.75822 16.1638 2.52467 15.8808 1.10289C15.8107 0.751602 15.773 0.389082 15.7694 0.0175781H12.0197V10.2634L12.0153 15.8754C12.0153 17.3758 11.0382 18.648 9.68381 19.0954C9.29074 19.2253 8.86623 19.2868 8.4242 19.2626C7.85998 19.2316 7.33125 19.0613 6.8717 18.7864C5.89375 18.2015 5.2307 17.1404 5.21273 15.9267C5.18443 14.0296 6.71807 12.4829 8.61377 12.4829C8.98797 12.4829 9.34734 12.544 9.68381 12.655V9.85457V8.84787C9.32893 8.79531 8.96775 8.76791 8.60254 8.76791C6.5276 8.76791 4.58697 9.63041 3.19979 11.1843C2.15131 12.3585 1.5224 13.8567 1.42537 15.4276C1.29824 17.4913 2.05338 19.453 3.51783 20.9004C3.73301 21.1129 3.95897 21.3101 4.19525 21.492C5.45082 22.4583 6.9858 22.9821 8.60254 22.9821C8.96775 22.9821 9.32893 22.9551 9.68381 22.9026C11.1941 22.6789 12.5876 21.9875 13.6872 20.9004C15.0385 19.5649 15.7851 17.7918 15.7932 15.9046L15.7739 7.52402C16.4185 8.02131 17.1233 8.43279 17.8798 8.75219C19.0563 9.24857 20.3038 9.50014 21.5877 9.49969V6.77697V5.76668C21.5886 5.76758 21.5778 5.76758 21.5769 5.76758Z"
            fill={themeColors.neutralBlack}
        />
    </svg>
);
