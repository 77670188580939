"use client";

import { __DEV__ } from "@/lib/constants";
import Link from "@/components/wrappers/Link";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { useDetectAdBlock } from "@/hooks/useDetectAdBlocker";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import Image from "next/image";
import { useEffect, useMemo } from "react";
import { AD_SIZES_BY_ID } from "./FreestarAdSlot";

const AdUnit = () => {
    const { smDown } = useBreakPoints();

    useEffect(() => {
        if (smDown) {
            return;
        }

        if (typeof window !== "undefined") {
            window.freestar = window.freestar || {};
            window.freestar.queue = window.freestar.queue || [];

            window.freestar.queue.push(() => {
                window.freestar.newStickyFooter();
            });
        }

        return () => {
            if (typeof window !== "undefined") {
                window.freestar = window.freestar || {};
                window.freestar.queue = window.freestar.queue || [];

                window.freestar.queue.push(() => {
                    window.freestar.deleteStickyFooter();
                });
            }
        };
    }, []);

    return null;
};

const ReplacementAdSlot = ({ adBlockDetected }: { adBlockDetected: boolean }) => {
    const { smDown, lgDown } = useBreakPoints();
    const deviceType = smDown ? "mobile" : lgDown ? "tablet" : "desktop";

    const { width, height, fallbacks: _fallbacks } = AD_SIZES_BY_ID.knowt_sticky_footer[deviceType];
    const fallbacks = _fallbacks(adBlockDetected);

    const fallbackIndex = useMemo(() => Math.floor(Math.random() * fallbacks.length), [fallbacks]);
    const adBlockerFallback = fallbacks[fallbackIndex];

    if (!adBlockerFallback) return null;

    return (
        <div
            style={{
                position: "fixed",
                bottom: 0,
                height: 90,
                width: "100%",
                display: "grid",
                alignContent: "center",
                justifyContent: "center",
                fontSize: 20,
                zIndex: 1200,
            }}>
            <Link href={adBlockerFallback.href} rel={"noopener noreferrer"} openInNewTab>
                <Image src={adBlockerFallback.image} alt={"raspy-tendons"} height={height} width={width} />
            </Link>
        </div>
    );
};

const FooterAds = () => {
    const { loginInProgress, user, isSubscriptionActive, isUnder13, canShowAds } = useCurrentUser();
    const adBlockDetected = useDetectAdBlock();

    if (isSubscriptionActive || isUnder13 || !user || loginInProgress || !canShowAds) {
        return null;
    }

    if (__DEV__) {
        return <ReplacementAdSlot adBlockDetected={adBlockDetected} />;
    }

    return <AdUnit />;
};

export default FooterAds;
